import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, FormGroup } from "@angular/forms";
import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Customer, CustomerService } from "./customer.service";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Address, Salutation } from "./keycloak-connector.service";
import { TaskOutcome } from "./process-service";
import { HealthScreening, HealthScreeningService } from "./health-screening.service";
import { FormHelper } from "../helper/form.helper";
import { Employee, EmployeeService } from "./employee.service";
import { EnvService } from "./env.service";

declare type EventState = "INITIAL" | "PENDING" | "ACCEPTED" | "REJECTED" | "UNANSWERED" | "BOOKED" | "RUNNING" | "COMPLETED" | "CANCELED";
declare type ScheduleEntryType = "BLOCKED" | "OPEN" | "UNAVAILABLE" | "CLOSED";

export class CorporateCustomerDTO {
  salutation: Salutation;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  companyName: string;
  street: string;
  number: string;
  zip: string;
  city: string;
  eventId: number;
  customerRegion: string;
  customerTeamnumber: string;
}

export class Chat {
  id: number;
  buid: string;
  referenceId: string;
  timestamp: Date;
  author: string;
  content: string;

  constructor() {
    this.id = null;
    this.buid = "";
    this.referenceId = "";
    this.timestamp = null;
    this.author = "";
    this.content = "";
  }
}

export class CustomerEventOverviewDTO {
  eventId: number;
  eventDate: Date;
  moduleName: string;
  corporateCustomerCompanyName: string;
  street: string;
  city: string;
  state: EventState;
  buid: string;
  signed: boolean;
  rated: boolean;
  canBeSignedAndRated: boolean;
  hasAnonymousEvaluationDocuments: boolean;
}

export class Event {
  id: number;
  eventDate: Date;
  customer: Customer;
  startTime: string;
  endTime: string;

  customerSalutation: Salutation;
  customerFirstname: string;
  customerLastname: string;
  customerPhone: string;
  customerMail: string;
  customerAddress: Address;
  customerCompanyName: string;
  customerRegion: string;
  customerTeamnumber: string;

  customerContactSalutation: Salutation;
  customerContactFirstname: string;
  customerContactLastname: string;
  customerContactPhone: string;
  customerContactMail: string;
  customerContactCompanyName: string;
  customerContactRegion: string;
  customerContactTeamnumber: string;

  corporateCustomerCompanyName: string;
  corporateCustomerContactSalutation: Salutation;
  corporateCustomerContactFirstname: string;
  corporateCustomerContactLastname: string;
  corporateCustomerContactPhone: string;
  corporateCustomerContactMail: string;

  timing: string;
  eventlocation: Address;
  comments: string;
  remark: string;
  createdDate: Date;
  createdBy: string;
  state: EventState;
  channel: string;
  showInAppointmentOverview: boolean;
  sendEmailToCorporateCustomer: boolean;
  eventHealthScreenings: EventInquiryHealthscreening[];
  moduleIntroduction: boolean;
  modulePresentation: boolean;
  moduleEnglish: boolean;
  anonymEvaluation: boolean;
  sendAppointmentlistToCorporateCustomer: boolean;
  parkingPossible: boolean;
  targetGroup: string;
  expectedAttendees: number;

  orderNumber: string;
  distanceFromHamburg: number;
  distanceFromBerlin: number;

  commentsAdditions: string;
  targetGroupComment: string;
  shortPresentation: boolean;
  skipSchedules: boolean;
  hospitant: string;
  lastUpdated: Date;
  scheduleEmailTimestamp: Date;
  bookingCopyRecepientMail: string;
  disableEmailCommunication: boolean;
  buid: string;
  estimatedBookingConfirmationDate: string;
  signoffConfirmation: boolean;
  signoffConfirmationTimestamp: Date;
  companyBonus: boolean;
  plannedLocation: string;
  parkingAlternative: string;
  noneOnSite: boolean;
  billingViaCorporateCustomer: boolean;
  saveCo2Paper: boolean;
  guestWifiOnSite: string;

  public constructor() {
    this.id = null;
    this.eventDate = null;
    this.customer = null;
    this.startTime = "";
    this.endTime = "";
    this.customerSalutation = null;
    this.customerFirstname = "";
    this.customerLastname = "";
    this.customerPhone = "";
    this.customerMail = "";
    this.customerAddress = new Address();
    this.customerAddress.type = "CUSTOMER_EVENT_ADDRESS";
    this.customerCompanyName = "";
    this.customerRegion = "";
    this.customerTeamnumber = "";

    this.customerContactSalutation = null;
    this.customerContactFirstname = "";
    this.customerContactLastname = "";
    this.customerContactPhone = "";
    this.customerContactMail = "";
    this.customerContactCompanyName = "";
    this.customerContactRegion = "";
    this.customerContactTeamnumber = "";

    this.corporateCustomerCompanyName = "";
    this.corporateCustomerContactSalutation = null;
    this.corporateCustomerContactFirstname = "";
    this.corporateCustomerContactLastname = "";
    this.corporateCustomerContactPhone = "";
    this.corporateCustomerContactMail = "";

    this.timing = "";
    this.eventlocation = new Address();
    this.eventlocation.type = "EVENT";
    this.comments = "";
    this.remark = "";
    this.createdDate = null;
    this.createdBy = "";
    this.state = "INITIAL";
    this.channel = "";
    this.showInAppointmentOverview = false;
    this.sendEmailToCorporateCustomer = false;
    this.eventHealthScreenings = [];
    this.moduleIntroduction = false;
    this.modulePresentation = false;
    this.moduleEnglish = false;
    this.anonymEvaluation = false;
    this.sendAppointmentlistToCorporateCustomer = false;
    this.parkingPossible = null; //false;
    this.targetGroup = "";
    this.expectedAttendees = null;

    this.orderNumber = "";
    this.distanceFromHamburg = null;
    this.distanceFromBerlin = null;

    this.commentsAdditions = "";
    this.targetGroupComment = "";
    this.skipSchedules = false;
    this.hospitant = "";
    this.lastUpdated = null;
    this.scheduleEmailTimestamp = null;
    this.bookingCopyRecepientMail = "";
    this.disableEmailCommunication = null;

    this.buid = null;
    this.estimatedBookingConfirmationDate = null;
    this.signoffConfirmation = null;
    this.signoffConfirmationTimestamp = null;
    this.companyBonus = false;

    this.plannedLocation = null;
    this.parkingAlternative = null;
    this.noneOnSite = false;
    this.billingViaCorporateCustomer = false;
    this.saveCo2Paper = true;
    this.guestWifiOnSite = "unknown";
  }
}

export class ScheduleEntry {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  companyname: string;
  timeFrom: string;
  timeTo: string;
  participantId: string;
  scheduletype: ScheduleEntryType;
  title: string;
  onlineModule: boolean;
  scheduleEntryCompanynameRequired: boolean;
  modulename: string;
  eventDate: Date;
  meetingUrl: string;
  meeetingId: string;
  acceptedEventPrivacy: boolean;
  acceptedThirdPartyPrivacy: boolean;
  sendAppointmentlistToCorporateCustomer: boolean;
  zip: string;

  constructor() {
    this.id = null;
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.phone = "";
    this.companyname = "";
    this.timeFrom = "";
    this.timeTo = "";
    this.participantId = "";
    this.scheduletype = "OPEN";
    this.title = "";
    this.onlineModule = false;
    this.modulename = "";
    this.eventDate = null;
    this.meeetingId = "";
    this.meetingUrl = "";
    this.acceptedEventPrivacy = false;
    this.acceptedThirdPartyPrivacy = false;
    this.scheduleEntryCompanynameRequired = false;
    this.zip = "";
    this.sendAppointmentlistToCorporateCustomer = false;
  }
}

export class WaitingListEntry {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  companyname: string;
  modulename: string;
  eventDate: Date;
  acceptedEventPrivacy: boolean;
  acceptedThirdPartyPrivacy: boolean;

  constructor() {
    this.id = null;
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.phone = "";
    this.companyname = "";
    this.modulename = "";
    this.eventDate = null;
    this.acceptedEventPrivacy = false;
    this.acceptedThirdPartyPrivacy = false;
  }
}

export class SignoffDto {
  eventId: number;
  comment: string;
}

export class EventInquiryHealthscreening {
  id: number;
  name: string;
  eventInquiry: Event;
  healthscreening: HealthScreening;
  timeFrom: string;
  timeTo: string;
  timing: number;
  presentation: boolean;
  presentationTimeFrom: string;
  presentationTimeTo: string;
  breakTime1From: string;
  breakTime1To: string;
  breakTime2From: string;
  breakTime2To: string;
  breakTime3From: string;
  breakTime3To: string;
  attachmentPrivacy: boolean;
  trainer: Employee[];
  hospitant: Employee[];
  scheduleEntries: ScheduleEntry[];
  hybrid: boolean;

  presentationBillOnly: boolean;
  anonymousEvaluation: boolean;
  anonymousEvaluationBillOnly: boolean;
  vehicle: string;
  internetboxReference: string;
  healthscreeningInvoicename: string;
  healthscreeningReference: string;
  presentationEnglish: boolean;

  vehiclePassengerCount: number;
  vehicleModuleDateFrom: Date;
  vehicleModuleDateTo: Date;
  vehicleModuleTimeFrom: string;
  vehicleModuleTimeTo: string;
  trainerEmailTimestamp: string;
  shortPresentation: boolean;
  onlineModule: boolean;
  pdfSchedule: boolean;
  onlineSchedule: boolean;
  scheduleEntryCompanynameRequired: boolean;
  urlForTrainer: string;
  waitingList: WaitingListEntry[];
  showWaitingList: boolean;

  healthScreeningTopic: string;
  onsiteInterval: number;
  onlineInterval: number;
  moduleType: string;
  maxParticipants: number;
  skipSchedule: boolean;
  registrationViaLandingPage: boolean;
  agentNote: string;

  constructor() {
    this.id = null;
    this.name = "";
    this.eventInquiry = null;
    this.healthscreening = null;
    this.timeFrom = "";
    this.timeTo = "";
    this.timing = null;
    this.presentation = false;
    this.presentationTimeFrom = "";
    this.presentationTimeTo = "";
    this.breakTime1From = "";
    this.breakTime1To = "";
    this.breakTime2From = "";
    this.breakTime2To = "";
    this.breakTime3From = "";
    this.breakTime3To = "";
    this.attachmentPrivacy = false;
    this.trainer = [];
    this.hospitant = [];
    this.scheduleEntries = [];
    this.hybrid = false;

    this.presentationBillOnly = false;
    this.anonymousEvaluation = false;
    this.anonymousEvaluationBillOnly = false;
    this.vehicle = "";
    this.internetboxReference = "";
    this.healthscreeningInvoicename = "";
    this.healthscreeningReference = "";
    this.presentationEnglish = false;

    this.vehiclePassengerCount = null;
    this.vehicleModuleDateFrom = null;
    this.vehicleModuleDateTo = null;
    this.vehicleModuleTimeFrom = "";
    this.vehicleModuleTimeTo = "";
    this.trainerEmailTimestamp = "";
    this.shortPresentation = false;
    this.onlineModule = false;
    this.pdfSchedule = false;
    this.onlineSchedule = true;
    this.scheduleEntryCompanynameRequired = false;
    this.urlForTrainer = "";
    this.showWaitingList = false;

    this.healthScreeningTopic = "";
    this.onsiteInterval = undefined;
    this.onlineInterval = undefined;
    this.moduleType = "";
    this.maxParticipants = undefined;
    this.skipSchedule = false;
    this.registrationViaLandingPage = false;
    this.agentNote = "";
  }
}

@Injectable()
export class EventService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private formHelper: FormHelper, private healthScreeningService: HealthScreeningService, private employeeService: EmployeeService, private customerService: CustomerService, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  startProcess(event: Event): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/eventInquiry/start/", event, this.config);
  }

  findAll(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry", this.config);
  }

  /*  findMyEvents(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/event/getMyEvents", this.config);
  }
*/
  findMyEventOverview(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/event/getMyEventsOverview", this.config);
  }

  findById(id: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/" + id, this.config);
  }

  findByBuid(buid: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/buid/" + buid, this.config);
  }

  findForTrainerByBuid(buid: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/forTrainer/" + buid, this.config);
  }

  findEihsByBuidAndTrainerId(buid: string, trainerId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/getEihsByBuidAndTrainerId?buid=" + buid + "&trainerId=" + trainerId, this.config);
  }

  findByProcessInstanceId(processInstanceId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiryByProcessInstanceId?processInstanceId=" + processInstanceId, this.config);
  }

  update(evnt: Event): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/eventInquiry/" + evnt.id, evnt, this.config);
  }

  cancel(eventId: number, forceDelete: boolean, reason: string, cancelationDate: Date): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/eventInquiry/cancel?eventId=" + eventId + "&forceDelete=" + forceDelete + "&reason=" + reason + "&cancelationDate=" + cancelationDate.toISOString(), this.config);
  }

  cancelModule(eventId: number, forceDelete: boolean, reason: string, eihsId: number, cancelationDate: Date): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/eventInquiry/cancelModule?eventId=" + eventId + "&forceDelete=" + forceDelete + "&reason=" + reason + "&eihsId=" + eihsId + "&cancelationDate=" + cancelationDate.toISOString(), this.config);
  }

  getSignOffPdfByBuid(buid: string) {
    return this.http.get(this.envService.backendUrl + "/event/getSignOffPdfByBuid?buid=" + buid, { responseType: "blob" });
  }

  getSignOffDummy(companynameAlias: string) {
    return this.http.get(this.envService.backendUrl + "/event/getSignOffDummy?companynameAlias=" + companynameAlias, { responseType: "blob" });
  }

  signoffEvent(dto: SignoffDto): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/event/signoff", dto, this.config);
  }

  sendLogisticsDataToTrainer(eventInquiry: Event, eventInquiryHealthscreeningId: number): Observable<HttpConfig> {
    const body = {
      eventInquiry,
      eventInquiryHealthscreeningId,
    };
    return this.http.post(this.envService.backendUrl + "/communication/sendLogisticsDataToTrainer", body, this.config);
  }

  sendAnonymousEvaluationNotification(buid: string): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/communication/sendAnonymousEvaluationNotification/" + buid, this.config);
  }

  customerResponse(buid: string, outcome: TaskOutcome, query: string, event: Event, messageName: string, processStep: string): Observable<HttpConfig> {
    const body = {
      buid,
      outcome,
      query,
      event,
      messageName,
      processStep,
    };
    return this.http.post(this.envService.backendUrl + "/public/eventInquiry/customerResponse", body, this.config);
  }

  getChatHistory(buid: string, referenceId: string): Observable<HttpConfig> {
    const url = this.envService.backendUrl + "/public/eventInquiry/chatHistory?buid=" + buid + "&referenceId=" + referenceId;
    return this.http.get(url, this.config);
  }

  getCorporateCustomerHistory(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/corporateCustomerHistory", this.config);
  }

  getCustomerContactHistory(companynameAlias: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/eventInquiry/customerContactHistory?companynameAlias=" + companynameAlias, this.config);
  }

  sendSchedules(eventId: number): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/communication/sendSchedules?eventId=" + eventId, {}, this.config);
  }

  sendReminderMailToOnlineEventParticipant(scheduleEntryId: number): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/communication/sendReminderMailToOnlineEventParticipant?scheduleEntryId=" + scheduleEntryId, {}, this.config);
  }

  prefillFormWithCustomerValues(eventForm: UntypedFormGroup, customer: Customer) {
    // this.formHelper.handleCustomerForForm(eventForm, "customer", customer);
    this.clearCustomer(eventForm, customer);

    eventForm.get("customerSalutation").setValue(customer.salutation);
    eventForm.get("customerFirstname").setValue(customer.firstname);
    eventForm.get("customerLastname").setValue(customer.lastname);
    eventForm.get("customerPhone").setValue(customer.phonenumber);
    eventForm.get("customerMail").setValue(customer.email);
    eventForm.get("customerCompanyName").setValue(customer.companyname);
    eventForm.get("customerRegion").setValue(customer.region);
    eventForm.get("customerTeamnumber").setValue(customer.teamnumber);
    if (eventForm.get("channel").value === "FORM" || eventForm.get("channel").value === "CALL" || eventForm.get("channel").value === "BOOKING") {
      const bookingCopyRecepientMailForForm = customer.bookingCopyRecepientMail ? customer.bookingCopyRecepientMail.split(", ") : [];
      eventForm.get("bookingCopyRecepientMail").setValue(bookingCopyRecepientMailForForm);
    }
    let billingAdress = null;
    if (customer.addresses && customer.addresses.length > 0) {
      for (const address of customer.addresses) {
        if (address.type === "BILLING") {
          billingAdress = address;
        }
      }
    }
    if (billingAdress) {
      eventForm.get("customerAddress").get("street").setValue(billingAdress.street);
      eventForm.get("customerAddress").get("number").setValue(billingAdress.number);
      eventForm.get("customerAddress").get("city").setValue(billingAdress.city);
      eventForm.get("customerAddress").get("zip").setValue(billingAdress.zip);
    }
  }

  clearCustomerValuesFromForm(eventForm: UntypedFormGroup) {
    // this is breaking all subscriptions, need to replace it by sofr clear
    // this.formHelper.handleCustomerForForm(eventForm, "customer", new Customer());
    // eventForm.get("customer").get("addresses").setValue([]);
    // eventForm.get("customer").get("bookingCopyRecepientMail").setValue([]);
    // eventForm.get("customer").get("comment").setValue("");
    // eventForm.get("customer").get("companyname").setValue("");
    // eventForm.get("customer").get("companynameAlias").setValue("");
    // eventForm.get("customer").get("email").setValue("");
    // eventForm.get("customer").get("enabled").setValue(null);
    // eventForm.get("customer").get("firstname").setValue("");
    // eventForm.get("customer").get("fullName").setValue("");
    // eventForm.get("customer").get("gpsUser").setValue(true);
    // eventForm.get("customer").get("lastname").setValue("");
    // eventForm.get("customer").get("mobilenumber").setValue("");
    // eventForm.get("customer").get("officialName").setValue("");
    // eventForm.get("customer").get("password").setValue("");
    // eventForm.get("customer").get("phonenumber").setValue("");
    // eventForm.get("customer").get("region").setValue("");
    // eventForm.get("customer").get("role").setValue("gib_customer");
    // eventForm.get("customer").get("salutation").setValue("");
    // eventForm.get("customer").get("state").setValue("");
    // eventForm.get("customer").get("teamnumber").setValue("");
    // eventForm.get("customer").get("username").setValue("");

    this.clearCustomer(eventForm, null);

    eventForm.get("customerSalutation").setValue(null);
    eventForm.get("customerFirstname").setValue("");
    eventForm.get("customerLastname").setValue("");
    eventForm.get("customerPhone").setValue("");
    eventForm.get("customerMail").setValue("");
    eventForm.get("customerCompanyName").setValue("");
    eventForm.get("customerRegion").setValue("");
    eventForm.get("customerTeamnumber").setValue("");
    eventForm.get("customerAddress").get("street").setValue("");
    eventForm.get("customerAddress").get("number").setValue("");
    eventForm.get("customerAddress").get("city").setValue("");
    eventForm.get("customerAddress").get("zip").setValue("");
  }

  private clearCustomer(eventForm: UntypedFormGroup, cust: Customer) {
    const customer: UntypedFormGroup = this.customerService.mapCustomerToForm(cust ? cust : new Customer());
    Object.keys((eventForm.get("customer") as FormGroup).controls).forEach((key) => {
      if (key !== "id") {
        (eventForm.get("customer") as FormGroup).removeControl(key);
      }
    });
    Object.keys(customer.controls).forEach((key) => {
      if (key !== "id") {
        (eventForm.get("customer") as FormGroup).addControl(key, customer.get(key));
      }
    });
  }

  mapFormToEvent(form: UntypedFormGroup): Event {
    const event = form.getRawValue();
    // remove customer, if it was just a dummy object from initialization(=> no id)
    if (event.customer && !event.customer.id) {
      event.customer = null;
    }
    this.handleEventHealthScreeningsFromForm(event, "eventHealthScreenings");
    this.handleEventHealthScreeningsFromForm(event, "canceledEventHealthScreenings");
    this.handleBookingCopyRecepientMailFromForm(event, form);
    if (event.customer) {
      this.customerService.handleBookingCopyRecepientMailFromForm(event.customer, form);
    }
    return event;
  }

  handleBookingCopyRecepientMailFromObject(event: Event, form: UntypedFormGroup) {
    const bookingCopyRecepientMailForForm = event.bookingCopyRecepientMail ? event.bookingCopyRecepientMail.split(", ") : [];
    form.get("bookingCopyRecepientMail").setValue(bookingCopyRecepientMailForForm);
  }

  handleBookingCopyRecepientMailFromForm(event: Event, form: UntypedFormGroup) {
    let bookingCopyRecepientMailForObject = form.get("bookingCopyRecepientMail").value ? form.get("bookingCopyRecepientMail").value.join(", ") : [];
    if (form.get("bookingCopyRecepientMailTemp") && form.get("bookingCopyRecepientMailTemp").value) {
      if (bookingCopyRecepientMailForObject === "") {
        bookingCopyRecepientMailForObject = form.get("bookingCopyRecepientMailTemp").value;
      } else {
        bookingCopyRecepientMailForObject = bookingCopyRecepientMailForObject + ", " + form.get("bookingCopyRecepientMailTemp").value;
      }
    }
    event.bookingCopyRecepientMail = bookingCopyRecepientMailForObject;
  }

  handleEventHealthScreeningsFromForm(event: Event, hsKey: string) {
    for (const ehs of event[hsKey]) {
      if (ehs.healthscreening.location && Array.isArray(ehs.healthscreening.location)) {
        ehs.healthscreening.location = ehs.healthscreening.location.join(", ");
        if (ehs.healthscreening.onlinePlatform) {
          ehs.healthscreening.onlinePlatform = ehs.healthscreening.onlinePlatform.join(", ");
        }
        ehs.healthscreening.topic = ehs.healthscreening.topic.join(", ");
      }
      if (!ehs.scheduleEntries) {
        ehs.scheduleEntries = [];
      }
      if (ehs.trainer) {
        for (const tr of ehs.trainer) {
          if (tr.location && Array.isArray(tr.location)) {
            tr.location = tr.location.join(", ");
          }
        }
      }
      if (ehs.healthscreening.visibileForCompanynameAliases) {
        this.healthScreeningService.handleVisibileForCompanynameAliasesForUpdate(ehs.healthscreening);
      }
    }
  }

  mapEventToForm(event: Event): UntypedFormGroup {
    const eventForm = this.formBuilder.group(event);
    this.formHelper.handleCustomerForForm(eventForm, "customer", event.customer);
    this.formHelper.handleAddressForForm(eventForm, "customerAddress", "CUSTOMER_EVENT_ADDRESS", event.customerAddress);
    this.formHelper.handleAddressForForm(eventForm, "eventlocation", "EVENT", event.eventlocation);
    this.handleEventHealthScreeningsFromObject(eventForm, event, "eventHealthScreenings");
    this.handleEventHealthScreeningsFromObject(eventForm, event, "canceledEventHealthScreenings");
    eventForm.addControl("bookingCopyRecepientMailTemp", new UntypedFormControl(""));
    this.handleBookingCopyRecepientMailFromObject(event, eventForm);
    return eventForm;
  }

  handleEventHealthScreeningsFromObject(form: UntypedFormGroup, event: Event, hsKey: string) {
    form.removeControl(hsKey);
    const eventHealthScreenings = event[hsKey];
    if (!eventHealthScreenings || eventHealthScreenings.length === 0) {
      form.addControl(hsKey, this.formBuilder.array([]));
    } else {
      const ehsArray = [];
      for (const ehs of event[hsKey]) {
        const ehsFormGroup = this.formBuilder.group(ehs);
        ehsFormGroup.removeControl("scheduleEntries");
        ehsFormGroup.addControl("scheduleEntries", this.createEventParticipantFormArray(ehs.scheduleEntries));
        ehsFormGroup.removeControl("trainer");
        ehsFormGroup.addControl("trainer", this.createEventTrainerFormArray(ehs.trainer));
        ehsFormGroup.removeControl("waitingList");
        ehsFormGroup.addControl("waitingList", this.createWaitingListFormArray(ehs.waitingList));
        ehsFormGroup.removeControl("healthscreening");
        if (!ehs.healthscreening || ehs.healthscreening.length === 0) {
          ehsFormGroup.addControl("healthscreening", this.formBuilder.array([]));
        } else {
          ehsFormGroup.addControl("healthscreening", this.healthScreeningService.createHealthscreeningForm(ehs.healthscreening));
        }
        ehsArray.push(ehsFormGroup);
      }
      form.addControl(hsKey, this.formBuilder.array(ehsArray));
    }
  }

  createEventParticipantFormArray(eParticipants: ScheduleEntry[]): UntypedFormArray {
    const eParticipantArray = [];
    if (!eParticipants) {
      eParticipants = [];
    }
    for (const participant of eParticipants) {
      eParticipantArray.push(this.formBuilder.group(participant));
    }
    return this.formBuilder.array(eParticipantArray);
  }

  createWaitingListFormArray(waitingList: WaitingListEntry[]): UntypedFormArray {
    const waitingListArray = [];
    if (!waitingList) {
      waitingList = [];
    }
    for (const waitingListEntry of waitingList) {
      waitingListArray.push(this.formBuilder.group(waitingListEntry));
    }
    return this.formBuilder.array(waitingListArray);
  }

  createEventTrainerFormArray(trainer: Employee[]): UntypedFormArray {
    const trainerArray = [];
    if (!trainer) {
      trainer = [];
    }
    for (const tr of trainer) {
      const trainerFormGroup = this.employeeService.mapEmployeeToForm(tr);
      trainerArray.push(trainerFormGroup);
    }
    return this.formBuilder.array(trainerArray);
  }

  createScheduleEntry(timeFrom, timeTo, scheduletype, title) {
    const se = new ScheduleEntry();
    se.timeFrom = timeFrom;
    se.timeTo = timeTo;
    se.scheduletype = scheduletype;
    se.title = title;
    return se;
  }

  getEventInquiryHealthscreeningIndex(event: any, eihsId: number) {
    let index = 0;
    for (const ehs of event.eventHealthScreenings) {
      if (ehs.id === eihsId) {
        return index + "";
        break;
      }
      index++;
    }
    return "-1";
  }

  isEveryModulescheduled(event: Event) {
    let isScheduled = true;
    for (const ehs of event.eventHealthScreenings) {
      if (!ehs.scheduleEntries && !ehs.skipSchedule) {
        isScheduled = false;
      } else if (ehs.scheduleEntries.length === 0 && !ehs.skipSchedule) {
        isScheduled = false;
      }
    }
    return isScheduled;
  }

  disableCustomerData(eventForm: UntypedFormGroup) {
    this.formHelper.disableControlsByName(eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
    this.formHelper.disableControlsByName(eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
  }

  enableCustomerData(eventForm: UntypedFormGroup) {
    this.formHelper.enableControlsByName(eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
    this.formHelper.enableControlsByName(eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
  }

  copyEventForm(eventForm: UntypedFormGroup) {
    eventForm.get("id").setValue(null);
    eventForm.get("buid").setValue(null);
    eventForm.get("orderNumber").setValue(null);
    eventForm.get("state").setValue("INITIAL");
    eventForm.get("customerAddress").get("id").setValue(null);
    if (eventForm.get("eventFeedbackCustomer")) {
      eventForm.get("eventFeedbackCustomer").setValue(null);
    }
    if (eventForm.get("signoffConfirmation")) {
      eventForm.get("signoffConfirmation").setValue(null);
    }
    if (eventForm.get("signoffConfirmationTimestamp")) {
      eventForm.get("signoffConfirmationTimestamp").setValue(null);
    }
    if (eventForm.get("signoffComment")) {
      eventForm.get("signoffComment").setValue(null);
    }
    if (eventForm.get("eventHealthScreenings").value) {
      for (const eihs of eventForm.get("eventHealthScreenings")["controls"]) {
        const healthscreening = eihs.get("healthscreening").value;
        const onlineModule = eihs.get("onlineModule").value;
        const onlineSchedule = eihs.get("onlineSchedule").value;
        const onlineInterval = eihs.get("onsiteInterval").value;
        const onsiteInterval = eihs.get("onsiteInterval").value;
        const attachmentPrivacy = eihs.get("attachmentPrivacy").value;
        const pdfSchedule = eihs.get("pdfSchedule").value;
        const moduleType = eihs.get("moduleType").value;
        const healthScreeningTopic = eihs.get("healthScreeningTopic").value;
        eihs.reset();
        eihs.removeControl("scheduleEntries");
        eihs.addControl("scheduleEntries", this.createEventParticipantFormArray([]));
        eihs.get("healthscreening").setValue(healthscreening);
        eihs.get("onlineModule").setValue(onlineModule);
        eihs.get("onlineInterval").setValue(onlineInterval);
        eihs.get("onsiteInterval").setValue(onsiteInterval);
        eihs.get("moduleType").setValue(moduleType);
        eihs.get("onlineSchedule").setValue(onlineSchedule);
        eihs.get("attachmentPrivacy").setValue(attachmentPrivacy);
        eihs.get("pdfSchedule").setValue(pdfSchedule);
        eihs.get("healthscreening").value.assignedUsers = [];
        eihs.removeControl("trainer");
        eihs.addControl("trainer", new UntypedFormControl());
        if (eihs.get("eventFeedbackTrainer")) {
          eihs.get("eventFeedbackTrainer").setValue(null);
        }
        eihs.get("trainer").setValue([]);
        eihs.get("healthScreeningTopic").setValue(healthScreeningTopic);
      }
    }
    eventForm.get("eventlocation").get("id").setValue(null);
    if (eventForm.get("distanceFromHamburg")) {
      eventForm.get("distanceFromHamburg").reset();
    }
    if (eventForm.get("distanceFromBerlin")) {
      eventForm.get("distanceFromBerlin").reset();
    }
    if (eventForm.get("distanceFromHamburg")) {
      eventForm.get("distanceFromHamburg").reset();
    }
    if (eventForm.get("cancelationDate")) {
      eventForm.get("cancelationDate").reset();
    }
    if (eventForm.get("cancelationReason")) {
      eventForm.get("cancelationReason").reset();
    }
    if (eventForm.get("lastUpdated")) {
      eventForm.get("lastUpdated").reset();
    }
    if (eventForm.get("scheduleEmailTimestamp")) {
      eventForm.get("scheduleEmailTimestamp").reset();
    }
    if (eventForm.get("canceledEventHealthScreenings")) {
      eventForm.get("canceledEventHealthScreenings").reset();
    }
    return eventForm;
  }
}
