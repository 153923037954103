import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { FormHelper } from "src/app/helper/form.helper";

@Component({
  selector: "gib-input-percentage",
  styleUrls: ["gib-input-percentage.component.scss"],
  template: `
    <mat-form-field class="example-full-width">
      <input [disabled]="disabled" (focusout)="trim()" trim matInput placeholder="{{ placeholder }} " [formControl]="inputControl" type="{{ type }}" gibPercentageDirective />
      <div matSuffix>%</div>
      <mat-hint>{{ hint }}</mat-hint>
      <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('email')">{{ "email_invalid" | translate }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('password')">{{ "password_invalid" | translate }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('noMobileNumber')">{{ "no_mobile_number" | translate }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('pwNotSame')">{{ "pwsNotSame" | translate }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('noValidNumber')">{{ noValidNumberErrorMessage }}</mat-error>
    </mat-form-field>
  `,
})
export class GibInputPercentageComponent implements OnInit, OnDestroy, OnChanges {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  noValidNumberErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() type = "text";
  @Input() disabled;
  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    // this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.placeholder && this.placeholder !== "") {
        this.placeholder = this.translateService.instant(placeholderInputValue);
      }
      if (this.hint !== "") {
        this.hint = this.translateService.instant(hintInputValue);
      }
    });

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.noValidNumberErrorMessage = this.formHelper.createNoValidNumberErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {
    if (this.disabled) {
      this.inputControl.disable();
    }
  }

  trim() {
    this.formHelper.trimWhitespace(this.control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      const { currentValue, previousValue } = changes.disabled;
      if (previousValue !== null) {
        if (currentValue) {
          this.inputControl.disable();
        } else {
          this.inputControl.enable();
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}
