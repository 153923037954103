import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AccountingOverviewDto, AccountingService, PaymentBuidDto } from "../../../services/accounting.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { EventService } from "../../../services/event.service";
import { FormHelper } from "../../../helper/form.helper";
import { InternalNote, InternalNoteService } from "../../../services/internal-note.service";
import { EventDetailsBottomSheetComponent } from "../../events/components/event-details-bottom-sheet/event-details-bottom-sheet.component";
import { MatDialog } from "@angular/material/dialog";
import { TkUzEihsDialogComponent } from "../components/tk-uz-eihs-dialog/tk-uz-eihs-dialog.component";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";

@Component({
  selector: "tk-uz-overview-table",
  templateUrl: "./tk-uz-overview-table.component.html",
  styleUrls: ["./tk-uz-overview-table.component.scss"],
})
export class TkUzOverviewTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set events(events: AccountingOverviewDto[]) {
    if (events) {
      this.data = events;
      this.dataSource = new MatTableDataSource(events);
      this.dataSource.paginator = this.paginator;
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
      this.totalCosts = this.data.reduce((acc, dto) => acc + dto.costTotal, 0);
      const formatter = new Intl.NumberFormat(this.lang, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.formattedCost = formatter.format(this.totalCosts);
      const formatterDate = new Intl.DateTimeFormat(this.lang, { month: "long", year: "numeric" });
      this.localizedMonthYear = formatterDate.format(this.dateControl.value.toDate());
    }
  }
  @Input() date: Date;
  @Input() type: string;
  @Output() move: EventEmitter<{ offset: number; date: Date }> = new EventEmitter();
  @Output() release: EventEmitter<AccountingOverviewDto> = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  data: AccountingOverviewDto[] = [];
  dataSource: MatTableDataSource<AccountingOverviewDto>;
  sort: any;

  dateControl = new FormControl(moment());
  offset = 0;

  displayedColumns: string[] = [
    "paymentStatus",
    "accountingStatus",
    "release",
    "edit",
    "eventDate",
    "orderNumber",
    "deliveryType",
    "moduleName",
    "presentationEnglish",
    "anonymousEvaluation",
    "consumableMaterials",
    "costPercentage",
    "costTotal",
    "region",
    "teamnumber",
    "moduleType",
    "topic",
    "durationInMilliseconds",
    "targetGroup",
    "corporateCustomerCompanyName",
    "location",
  ];

  subscriptions: Subscription[] = [];
  userRole: string;
  totalCosts: number;
  formattedCost: string;
  localizedMonthYear: string;
  lang: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    private eventService: EventService,
    private formHelper: FormHelper,
    private internalNoteService: InternalNoteService,
    private dialog: MatDialog,
    private accountingService: AccountingService,
    private translateService: TranslateService,
    private dialogService: GibDialogService
  ) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem("role");
    this.lang = localStorage.getItem("gib-locale");
    if (this.type !== "allBookings" || (this.userRole !== "gib_admin" && this.userRole !== "gib_director")) {
      const index = this.displayedColumns.indexOf("release");
      this.displayedColumns.splice(index, 1);
    }
    if (this.userRole !== "gib_admin" && this.userRole !== "gib_director") {
      const index = this.displayedColumns.indexOf("orderNumber");
      this.displayedColumns.splice(index, 1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.date) {
      this.dateControl.setValue(moment(this.date));
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  editGlobalRates() {
    console.log("editGlobalRates");
  }

  moveDate(offset: number) {
    this.offset = offset;
    this.move.emit({ offset, date: null });
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.dateControl.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateControl.setValue(ctrlValue);
    datepicker.close();
    this.move.emit({ offset: null, date: this.dateControl.value.toDate() });
  }

  onEdit(row: AccountingOverviewDto) {
    const role = localStorage.getItem("role");
    if (role === "gib_admin" || role === "gib_director" || role === "gib_employee") {
      this.openDocument(row);
    } else {
      this.openDownloadPdfConfirmation("" + row.buid);
    }
  }

  private openDocument(row: AccountingOverviewDto) {
    this.eventService.findByBuid("" + row.buid).subscribe((res) => {
      const eventInquiry = res.body;
      const eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(res.body, row.eihsId);
      const eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(eventForm);
      const role = localStorage.getItem("role");
      if (role === "gib_admin" || role === "gib_director" || role === "gib_employee") {
        this.internalNoteService.getById(row.eventId, localStorage.getItem("role")).subscribe((res) => {
          let internalNote = res.body;
          if (internalNote === null) {
            internalNote = new InternalNote();
          }
          const internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
          this.formHelper.disableControls(internalNoteForm);
          this.bottomSheet
            .open(EventDetailsBottomSheetComponent, { data: { eventForm, isEditable: this.isEditable(eventInquiry), selectedIndex: eihsIndex, internalNoteForm, allowModuleReplace: true } })
            .afterDismissed()
            .subscribe(() => {
              this.refresh.emit();
            });
        });
      } else {
        this.bottomSheet
          .open(EventDetailsBottomSheetComponent, { data: { eventForm } })
          .afterDismissed()
          .subscribe(() => {
            this.refresh.emit();
          });
      }
    });
  }

  onRelease(row: AccountingOverviewDto): void {
    const dialogRef = this.dialog.open(TkUzEihsDialogComponent, {
      data: {
        title: row.moduleName,
        costTotal: row.costTotal,
      },
      width: "500px",
    });
    const dialogSub = dialogRef.afterClosed().subscribe((res: { event: boolean; costTotal: number }) => {
      const { event, costTotal } = res;
      if (event) {
        const dto: AccountingOverviewDto = {
          ...row,
          costTotal,
        };
        this.release.emit(dto);
      }
    });
    this.subscriptions.push(dialogSub);
  }

  private isEditable(data: any) {
    return data.state !== "COMPLETED" && data.state !== "REJECTED" && data.state !== "CANCELED" && data.state !== "CANCELED_BEFORE_BOOKED" && data.state !== "UNANSWERED" && data.eventId !== -1000;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  openApprovePayment(): void {
    const title = this.translateService.instant("APPROVE_PAYMENT_CONFIRMATION_TITLE");
    const text = this.translateService.instant("APPROVE_PAYMENT_CONFIRMATION_TEXT", { month: this.localizedMonthYear, totalCosts: this.formattedCost });
    this.dialogService.openConfirmationDialog(title, text, () => this.approvePayment());
  }

  approvePayment(): void {
    let paymentApproveDto: PaymentBuidDto = {
      buidList: Array.from(new Set(this.data.map((d) => d.buid))),
    };
    this.accountingService.approvePayment(paymentApproveDto).subscribe(
      () => {
        const title = this.translateService.instant("APPROVE_PAYMENT_SUCCESS_TITLE");
        const text = this.translateService.instant("APPROVE_PAYMENT_SUCCESS_TEXT", { month: this.localizedMonthYear, totalCosts: this.formattedCost });
        this.dialogService.openDialog(title, text, "success");
        this.refresh.emit();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openSendPayment(): void {
    const title = this.translateService.instant("SEND_PAYMENT_CONFIRMATION_TITLE");
    const text = this.translateService.instant("SEND_PAYMENT_CONFIRMATION_TEXT", { month: this.localizedMonthYear, totalCosts: this.formattedCost });
    this.dialogService.openConfirmationDialog(title, text, () => this.sendPayment());
  }

  sendPayment(): void {
    let paymentApproveDto: PaymentBuidDto = {
      buidList: Array.from(new Set(this.data.map((d) => d.buid))),
    };
    this.accountingService.sendPayment(paymentApproveDto).subscribe(
      () => {
        const title = this.translateService.instant("SEND_PAYMENT_SUCCESS_TITLE");
        const text = this.translateService.instant("SEND_PAYMENT_SUCCESS_TEXT", { month: this.localizedMonthYear, totalCosts: this.formattedCost });
        this.dialogService.openDialog(title, text, "success");
        this.refresh.emit();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  anythingToApprove(): boolean {
    return this.data.length == 0;
  }

  mapTargetGroup(targetGroup: string): string {
    let retval = "";
    if (targetGroup) {
      const targetGroupArray = targetGroup.split("|,|");
      for (const targetGroupItem of targetGroupArray) {
        if (targetGroupItem.startsWith("other")) {
          const otherArray = targetGroupItem.split("|:|");
          if (otherArray.length > 1) {
            retval = retval + this.translateService.instant("other") + ": " + otherArray[1] + ", ";
          }
        } else {
          retval = retval + this.translateService.instant(targetGroupItem) + ", ";
        }
      }
    }
    if (retval.length > 0) {
      retval = retval.slice(0, -2);
    }
    return retval;
  }

  getTranslatedPaymentStatus(dto: AccountingOverviewDto): string {
    let status = dto.paymentStatus;
    if (status === "PAYMENT_DRAFT" || status === "PAYMENT_OPEN") {
      return this.translateService.instant(status);
    }
    if (this.userRole === "gib_tkcontrolling") {
      status = status + "_TK";
    } else {
      status = status + "_GIB";
    }
    if (status.startsWith("PAYMENT_SENT")) {
      return this.translateService.instant(status, { date: dto.invoiceSentDate });
    }
    return this.translateService.instant(status, { date: dto.paymentDate });
  }

  openDownloadPdfConfirmation(buid: string) {
    const title = this.translateService.instant("downloadConfirmationTitle");
    const text = this.translateService.instant("downloadPdfConfirmationText");
    this.dialogService.openConfirmationDialog(title, text, () => this.getEventPdf(buid));
  }

  getEventPdf(buid: string) {
    this.accountingService.getEventPdf(buid).subscribe((res) => {
      const blob = new Blob([res], { type: "application/octet-stream" });
      let downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = "buchungsformular.pdf";
      a.href = downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }
}
