import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FileService } from "../../services/file.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { GibDialogService } from "../dialogs/gib-dialog.service";

@Component({
  selector: "gib-fileupload",
  templateUrl: "./gib-fileupload.component.html",
  styleUrls: ["./gib-fileupload.component.scss"],
})
export class GibFileuploadComponent implements OnInit {
  @ViewChild("file", { static: true }) fileInput: ElementRef;

  fileControl: UntypedFormControl;

  trimmedFileName = "";

  uploadResponse = { status: "", message: "", filePath: "" };
  error;
  uploadComplete = false;
  showProgressBar = false;
  @Input() type;
  @Input() fileType;

  @Input() healthScreeningId: number;
  @Input() userId: string;

  @Input() maxFileSize: number;

  @Input() set control(controlObj: UntypedFormControl) {
    this.fileControl = controlObj;
  }

  @Input() title?: string;

  @Output() fileUploaded = new EventEmitter<null>();

  get control(): UntypedFormControl {
    return this.fileControl;
  }

  private validSignatures = {
    png: "89504e47", // PNG signature
    jpg: ["ffd8ffe0", "ffd8ffe1", "ffd8ffe2"], // JPG signatures
  };

  constructor(private dialogService: GibDialogService, private fileService: FileService, private translateService: TranslateService) {}

  ngOnInit() {}

  onFileChange(event) {
    this.uploadComplete = false;
    if (event.target.files.length > 0) {
      if (this.fileType === "CUSTOMER_SIGNATURE_DOCUMENT") {
        this.handleCustomerSignature(event);
      } else {
        const file = event.target.files[0];
        if (file.size < this.maxFileSize * 1000000) {
          // max 5MB atm 5000000
          this.error = null;
          if (file.name.length > 30) {
            this.trimmedFileName = file.name.substring(0, 15) + "..." + file.name.substring(file.name.length - 7, file.name.length);
          } else {
            this.trimmedFileName = file.name;
          }
          this.fileControl.setValue(file);
        } else {
          this.error = this.translateService.instant("fileTooBig", { maxFileSize: this.maxFileSize });
        }
      }
    }
  }

  private handleCustomerSignature(event: any) {
    const file: File = event.target.files[0];
    this.validateFile(file)
      .then((isValid) => {
        if (isValid) {
          if (file.size < this.maxFileSize * 1000000) {
            // max 5MB atm 5000000
            this.error = null;
            if (file.name.length > 30) {
              this.trimmedFileName = file.name.substring(0, 15) + "..." + file.name.substring(file.name.length - 7, file.name.length);
            } else {
              this.trimmedFileName = file.name;
            }
            this.fileControl.setValue(file);
          } else {
            this.error = this.translateService.instant("fileTooBig", { maxFileSize: this.maxFileSize });
          }
        } else {
          const text = this.translateService.instant("wrongFileSuffix");
          const title = this.translateService.instant("signature");
          this.dialogService.openDialog(title, text, "ok");
        }
      })
      .catch((error) => {
        const text = this.translateService.instant("wrongFileSuffix");
        const title = this.translateService.instant("signature");
        this.dialogService.openDialog(title, text, "ok");
      });
  }

  async validateFile(file: File): Promise<boolean> {
    if (!file) {
      throw new Error("No file provided");
    }

    const fileSignature = await this.readFileSignature(file, 4);

    if (file.type === "image/png" && fileSignature === this.validSignatures.png) {
      return true;
    }

    if (file.type === "image/jpeg" && this.validSignatures.jpg.includes(fileSignature)) {
      return true;
    }

    return false;
  }

  /**
   * Reads the first `numBytes` bytes of the file and returns the hexadecimal representation.
   * @param file The file to read.
   * @param numBytes The number of bytes to read.
   * @returns A Promise resolving to the hexadecimal string of the file's signature.
   */
  private async readFileSignature(file: File, numBytes: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const arrayBuffer = fileReader.result as ArrayBuffer;
        const bytes = new Uint8Array(arrayBuffer).subarray(0, numBytes);
        const hexString = Array.from(bytes)
          .map((byte) => byte.toString(16).padStart(2, "0"))
          .join("");
        resolve(hexString);
      };

      fileReader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      fileReader.readAsArrayBuffer(file.slice(0, numBytes));
    });
  }

  onSubmit() {
    this.showProgressBar = true;
    const formData = new FormData();
    formData.append("file", this.fileControl.value);

    if (this.userId) {
      this.fileService.uploadForOtherUser(formData, this.userId, this.type, this.healthScreeningId, this.title ? this.title : undefined).subscribe(
        (res) => {
          this.uploadResponse = res;
          if (res.id) {
            setTimeout(() => {
              this.uploadComplete = true;
              this.fileUploaded.emit(null);
              this.control.setValue(null);
              this.trimmedFileName = "";
              this.fileInput.nativeElement.value = null;
            }, 1000);
          }
        },
        (err) => (this.error = err)
      );
    } else {
      this.fileService.upload(formData, this.type, this.healthScreeningId, this.title ? this.title : undefined).subscribe(
        (res) => {
          this.uploadResponse = res;
          if (res.id) {
            setTimeout(() => {
              this.uploadComplete = true;
              this.fileUploaded.emit(null);
              this.control.setValue(null);
              this.trimmedFileName = "";
              this.fileInput.nativeElement.value = null;
            }, 1000);
          }
        },
        (err) => (this.error = err)
      );
    }
  }
}
