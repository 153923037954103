import { FaqService } from "./services/faq.service";
import { ReportService } from "./services/report.service";
import { EventEmitterService } from "./services/eventEmitterService";
import { CartService } from "./services/cart.service";
import { MailLogService } from "./services/mail-log.service";
import { FeedbackService } from "./services/feedback.service";
import { ScheduleService } from "./services/schedule-vote.service";
import { EventOverviewService } from "./services/event-overview.service";
import { NgModule, APP_INITIALIZER, Injector, LOCALE_ID } from "@angular/core";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";
import { ThemeModule } from "./utils/theme/theme.module";
import { defaultTheme } from "./utils/theme/default-theme";
import { darkTheme } from "./utils/theme/dark-theme";
import { PagesModule } from "./pages/pages.module";
import { UtilsModule } from "./utils/utils.module";
import { ComponentsModule } from "./components/components.module";
import { AppRoutingModule } from "./app-routing.module";
import { LOCATION_INITIALIZED, CommonModule } from "@angular/common";
import { EmployeeService } from "./services/employee.service";
import { FormHelper } from "./helper/form.helper";
import { CustomerService } from "./services/customer.service";
import { initializer } from "./utils/app-init";
import { KeycloakService, KeycloakAngularModule } from "keycloak-angular";
import { AppAuthGuard } from "./utils/authentication/app-auth-guard";
import { ProfileService } from "./services/profile.service";
import { FileService } from "./services/file.service";
import { ProcessService } from "./services/process-service";
import { HealthScreeningService } from "./services/health-screening.service";
import { EventService } from "./services/event.service";
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from "@angular/material/core";
import { MomentUtcDateAdapter } from "./utils/material/moment-utc-date-adapter";
import { SelectOptions } from "./utils/select-options";
import { CalendarModule, DateAdapter as AngularCalendarDateadapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import * as moment from "moment";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import { EventGridMockComponent } from "./test/event-grid-mock/event-grid-mock.component";
import { MeetingService } from "./services/meeting.service";
import { InternalNoteService } from "./services/internal-note.service";
import { HealthInsuranceService } from "./services/health-insurance.service";
import { EnvServiceProvider } from "./services/env.service.provider";
import { EnvService } from "./services/env.service";
import { WaitingListService } from "./services/waiting-list.service";
import { CustomValidators } from "./utils/custom-validator";
import { MetaReducer, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { RouterStateSerializer, StoreRouterConnectingModule } from "@ngrx/router-store";

import { reducers, effects } from "./store";
import { CustomSerializer } from "./store/reducers/router.reducer";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { storeFreeze } from "ngrx-store-freeze";

const environment = {
  development: true,
  production: false,
};

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

registerLocaleData(localeDe);

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = "de";
        translate.setDefaultLang("de");
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json?build_id=1741842926");
}

@NgModule({
  declarations: [AppComponent, EventGridMockComponent],
  imports: [
    ComponentsModule,
    PagesModule,
    UtilsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CommonModule,
    ThemeModule.forRoot({
      themes: [defaultTheme, darkTheme],
      active: "default",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CalendarModule.forRoot({ provide: AngularCalendarDateadapter, useFactory: momentAdapterFactory }),
    StoreModule.forRoot({}),
    StoreModule.forFeature("gib", reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    environment.development ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, ProfileService, EnvService],
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    EmployeeService,
    CustomerService,
    FileService,
    FormHelper,
    AppAuthGuard,
    ProfileService,
    ProcessService,
    HealthScreeningService,
    EventService,
    EventOverviewService,
    ScheduleService,
    WaitingListService,
    FeedbackService,
    MailLogService,
    MeetingService,
    InternalNoteService,
    EventEmitterService,
    ReportService,
    CartService,
    FaqService,
    HealthInsuranceService,
    EnvServiceProvider,
    CustomValidators,
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    { provide: LOCALE_ID, useValue: "de" },
    { provide: MAT_DATE_FORMATS, useValue: SelectOptions.dateFormats },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
