import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
  title: string;
  text: string;
  costTotal: number;
}

@Component({
  selector: "tk-uz-eihs-dialog",
  styleUrls: ["tk-uz-eihs-dialog.component.scss"],
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <gib-input-currency [placeholder]="'costTotal'" [control]="costTotal"></gib-input-currency>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class="btn btn-secondary" mat-button (click)="cancelAction()">{{ "CANCEL" | translate }}</button>
      <button class="btn btn-primary" mat-button (click)="releaseAction()" cdkFocusInitial>{{ "accRelease" | translate }}</button>
    </div>
  `,
})
export class TkUzEihsDialogComponent implements OnInit {
  form: FormGroup = this.fb.group({
    costTotal: [null, Validators.required],
  });

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<TkUzEihsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.costTotal.setValue(this.data.costTotal);
  }

  cancelAction(): void {
    this.dialogRef.close({ event: false, costTotal: this.costTotal.value });
  }

  releaseAction(): void {
    this.dialogRef.close({ event: true, costTotal: this.costTotal.value });
  }

  get costTotal(): FormControl {
    return this.form.get("costTotal") as FormControl;
  }
}
